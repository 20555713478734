//垃圾活动主题列表
const getActivityList = "/gateway/hc-low-carbon/manageapi/garbage/topicList";
//垃圾分类活动未参与房间列表
const getNotPartyPeopleList =
  "/gateway/hc-low-carbon/manageapi/garbage/topicUnJoinList";
//垃圾分类活动主题增加|编辑
const activityAdd = "/gateway/hc-low-carbon/manageapi/garbage/topicAddOrEdit";
//垃圾分类活动主题详情
const activityDetails = "/gateway/hc-low-carbon/manageapi/garbage/topicInfo";
//垃圾分类活动主题删除
const activityCancel = "/gateway/hc-low-carbon/manageapi/garbage/topicDelete";
//获取建筑列表
// const getBuildListURL = `/gateway/hc-space/space/building-list`;
const getBuildListURL = `/gateway/hc-space/space/getSpaceTreeList`;

export {
  getActivityList,
  getNotPartyPeopleList,
  activityAdd,
  activityDetails,
  activityCancel,
  getBuildListURL,
};
