import { mapHelper } from "@/utils/common.js";

const activityStatus = [
  { value: 1, label: "上架" },
  { value: 2, label: "下架" },
];
const { map: activityStatusMap, setOps: activityStatusOps } =
  mapHelper.setMap(activityStatus);
const userType = [
  {
    value: "4",
    checked: false,
    label: "居民",
  },
  // {
  //   value: "5",
  //   checked: false,
  //   label: "企业/商户",
  // },
  // {
  //   value: "21",
  //   checked: false,
  //   label: "运营人员",
  // },
];

const { map: userTypeMap, setOps: setUserTypeOps } = mapHelper.setMap(userType);
export { activityStatus, activityStatusMap, setUserTypeOps };
