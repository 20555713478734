<template>
  <div class="apply-activity">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :searchParam="searchParam"
      :table-url="tableUrl"
    >
      <template #headBtnSlot>
        <v-button text="新增" @click="toAdd"></v-button>
      </template>
      <template #searchSlot>
        <v-input label="搜索" v-model="searchParam.title" />
        <v-select
          clearable
          :options="activityStatus"
          v-model="searchParam.shelfSts"
          @change="$refs.list.search()"
          placeholder="请选择"
          label="发布状态"
        />
      </template>
      <template #operateSlot="scope">
        <v-button
          text="编辑"
          v-if="scope.row.canEdit == undefined || scope.row.canEdit"
          type="text"
          @click="toEdit(scope.row)"
        />
        <v-button
          text="删除"
          type="text"
          @click="activityDelete(scope.row.id)"
        />
      </template>
    </v-list>
  </div>
</template>

<script>
import { createAlinkVNode, createImgVNode } from "@/utils/utils.js";
import { activityStatusMap, activityStatus } from "./map";
import {
  getActivityList,
  activityCancel,
} from "@/views/rubbishSort/activity/api";

export default {
  name: "applyActivity",
  data() {
    return {
      activityStatus,
      searchParam: {
        title: null,
        shelfSts: null,
      },
      headers: [
        {
          prop: "title",
          label: "活动名称",
        },
        {
          prop: "joinHouseCount",
          label: "参与房数",
          align: "center",
        },
        {
          prop: "qualifiedCount",
          label: "合格房数",
          align: "center",
        },
        {
          prop: "qualifiedRate",
          label: "合格率",
          align: "center",
          formatter: (row) => {
            return (+row.qualifiedCount / +row.allHouseCount) * 100;
          },
        },
        {
          // prop: "nonParticipatingRooms",
          prop: "answerCount",
          label: "未参与房数",
          align: "center",
          formatter: (row, prop) =>
            createAlinkVNode(this, row, prop, {
              text: +row.allHouseCount - +row.joinHouseCount,
              cb: () =>
                this.$router.push({
                  name: "notPartyPeople",
                  query: {
                    id: row.id,
                  },
                }),
            }),
        },
        {
          prop: "intime",
          label: "发布开始时间",
        },
        {
          prop: "startDate",
          label: "开始时间",
        },
        {
          prop: "endDate",
          label: "结束时间",
        },
        {
          prop: "shelfSts",
          label: "发布状态",
          align: "center",
          formatter: (row) => {
            return activityStatusMap[row.shelfSts];
          },
        },
      ],
      tableUrl: getActivityList,
    };
  },
  methods: {
    toAdd() {
      this.$router.push({ name: "rubEditActivity" });
    },
    toEdit(data) {
      this.$router.push({
        name: "rubEditActivity",
        query: {
          id: data.id,
        },
      });
    },
    activityDelete(id) {
      this.$confirm("是否确定操作?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const params = {
          topicId: id,
        };
        this.$axios({
          method: "post",
          url: activityCancel,
          params: params,
        }).then((res) => {
          if (res.code === 200) {
            this.$message.success("操作成功");
            this.$refs.list.search();
          }
        });
      });
    },
  },
};
</script>
<style lang="less" scoped>
.apply-activity {
  box-sizing: border-box;
  height: 100%;
}
</style>
